body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
.form__field:not(:placeholder-shown) ~ .form__label {
  top: 0;
}
.datefield input.inputField.calendarDate {
  height: 35px !important;
}

.floatingButton {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floatingButton .button-icon {
  background-color: var(--main);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  border: 1px solid var(--grey-10);
  cursor: pointer;
  transition: .25s;
}
.floatingButton .button-icon #info {
  color: white !important;
}



.floatingButton .floating-content {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  transform: scale(0);
  opacity: 0;
  transform-origin: bottom;
  transition: .25s;
}

.floatingButton:hover .floating-content {
  transform: scaleY(1);
  opacity: 1;

}

.floatingButton .floating-content .icon-wrapper {
  background-color: #fd8428cc;
  background: linear-gradient(135deg,var(--main) 25%, var(--sub) 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  color: white;
  transform: scale(0);
  opacity: 0;
  transform-origin: center;
  transition: .25s;
  cursor: pointer;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  margin-bottom: 10px;

}

.floatingButton:hover .floating-content .icon-wrapper{
  /* background-color: var(--main_soft); */
  transform: scaleY(1);
  opacity: 1;

}

.floatingButton .floating-content .icon-wrapper:first-child:after {
  content: "Contact";
}
.floatingButton .floating-content .icon-wrapper:last-child:after {
  content: "OTP aanvragen";
}
.floatingButton .floating-content .icon-wrapper::after {
  content: "Bestelbon";
  height: 70%;
  width: 0;
  right: 40px;
  position: absolute;
  font-size: 13px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  /* background: #fd8628b5; */
  background: linear-gradient(135deg,var(--main) 25%, #fd8628b5 75%);
  color: transparent;
  border-radius: 5px;
  padding: 0 5px;
  transition: .2s;
  overflow: hidden;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  justify-content: center;
}
.floatingButton .floating-content .icon-wrapper:hover::after {
  width: 90px;
  max-width: max-content;
  color: white;
}

/* orderBlock */

.orderBlock .tab {
  flex: 1;
  font-size: 16px;
  white-space: nowrap;
}
.orderBlock .tab.active {
  color: var(--main);
}
.orderBlock {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderBlock .background {
  background-color: black;
  opacity: .5;
  position: fixed;
  width: 100%;
  height: 100%;
}
.orderBlock .content {
  background-color: white;
  max-height: 95vh;
  width: 95vw;
  border: 1px solid var(--grey-30);
  border-radius: 5px;
  z-index: 1;
  position: fixed;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow-y: scroll;
  height: max-content;
  top: 20px;
}
.orderBlock .content .sections {
  display: flex;
  transition: .2s;
  position: relative;
}
.orderBlock .sections.disable .pack-selectbox {
  pointer-events: none;
  background-color: var(--grey-5);
}
.orderBlock .sections.disable .pack-selectbox .pack.active {
  background-color: var(--grey-20);
}
.orderBlock .sections.disable .label {
  background-color: var(--grey-20);
  border: 1px solid var(--grey-30);
  border-radius: 5px;
}
.orderBlock .sections.disable .inputDiv label {
  background-color: transparent !important;
}
.orderBlock .content .section {
  min-width: 100%;
  height: 0;
  overflow: hidden;
}
.orderBlock .content .section.active {
  height: max-content !important;
  overflow: unset !important;
}
.orderBlock .content .back {
  position: absolute;
  top: 10px;
  left: 5px;
  padding: 5px;
  color: var(--grey-100);
  cursor: pointer;
  z-index: 999999;
}
.orderBlock .content .close {
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 5px;
  color: var(--grey-100);
  cursor: pointer;
  z-index: 999999;
}
.orderBlock .content .section h2 {
    align-items: center;
    border-bottom: 1px solid var(--grey-10);
    color: var(--grey-90);
    display: flex;
    font-size: 18px;
    font-weight: unset;
    margin: 0 auto;
    min-width: 100%;
    padding: 25px;
    padding-bottom: 20px;
}
.orderBlock .content .section h2.second {
  justify-content: space-between;
  align-items: flex-end;
}
.orderBlock .content .section h2.second .form__group {
  margin: 0 !important;
}
.orderBlock .content .section h2.second h6 {
  margin: 0 0 10px 0;
  font-weight: 300;
  color: var(--grey-90);
}
.orderBlock .content .section h2 span {
  color: var(--main);
  font-family: ShadowIntroLigth;
  margin-right: 10px;
  font-size: 28px;
}

.orderBlock .content .section h2 .title-item {
  flex: 1;
  max-width: 25%;
  position: relative;
}
.orderBlock .content .section h2.second .autosuggestion {
  position: absolute;
  top: 68px;
  background-color: white;
  z-index: 2;
  width: 100%;
  border: none;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: scroll;
  padding: 0;
  transition: .2s;
}
.orderBlock .content .section h2.second .autosuggestion.active {
  border: 1px solid var(--grey-30);
}
.orderBlock .content .section h2.second .autosuggestion div {
  padding: 5px 10px;
  transition: .2s;
  cursor: pointer;
}
.orderBlock .content .section h2.second .autosuggestion div:hover {
  background-color: var(--grey-10);
}

.orderBlock .content .section .section-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  line-height: 1.5;
  color: var(--grey-90);
  overflow-y: scroll;
}
.orderBlock .content .section .section-content h5 {
  color: white;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  background-color: red;
  width: 100%;
  font-size: 16px;
}
.orderBlock .content .section .section-content p {
  color: var(--grey-80);
  margin: 25px auto;
  padding: 5px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid var(--grey-30);
}
.orderBlock .content .section .section-content .inputs {
  display: flex;
  width: 50%;
  gap: 30px;
  margin: 15px 0;
}
.orderBlock .content .section .section-content .inputs .inputDiv {
  flex: 1;
  position: relative;
}
.orderBlock .content .section .section-content .inputs .form__group.datefield,
.orderBlock .content .section .section-content .inputs .inputFieldWrapper {
  flex: 1;
}
.orderBlock .content .section .section-content .inputs .inputFieldWrapper {
  max-width: calc(50% - 15px) !important;
}
.orderBlock .content .section .section-content .inputs .inputFieldWrapper input {
  max-width: unset;
  width: 100% !important;
}
.orderBlock .content .section .section-content .inputs .calendarDate,
.orderBlock .content .section .section-content .inputs .form__field.inputField {
  border: 1px solid var(--grey-20) !important;
}

.orderBlock .content .section .section-content label {
  color: var(--grey-90) !important;
}

.orderBlock .form__group { margin-top: 0;}

.orderBlock .content .section .section-content .inputs .selectLabel{
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
  display: block;
  color: var(--grey-90);
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 1;
  background-color: white;
}


.orderBlock .content .section .section-content .inputs .selectField .selectFieldOption > div {
  white-space: nowrap;
}

/* .orderBlock .content .inputFieldWrapper, .orderBlock .content .inputFieldWrapper .inputField, .orderBlock .content .form__group.datefield { 
  width: unset !important;
  min-width: 250px !important;
} */
.content .form__group.datefield input:focus {
  outline: none;
}

.orderBlock .content .section .section-content table {
  border: 1px solid var(--grey-50);
  color: var(--grey-70);
}
.orderBlock .content .section .section-content th {
  border-bottom: 1px solid var(--grey-50);
  padding: 10px 0;
}
.orderBlock .content .section .section-content tr td{
  padding: 10px;
  text-align: justify;
}
.orderBlock .content .section .section-content tr td span {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 900;
}
.orderBlock .content .section .section-content .error {
  color: red;
  font-weight: 900;
}
.orderBlock .content .section .section-content span.error {
  display: inline;
}
.orderBlock .content .section .section-content .th {
  border-top: 1px solid var(--grey-50);
}
.orderBlock .content .section .calendarDate {
  margin-bottom: 0 !important;
}
.sigCanvas {
  border: 1px solid var(--grey-10);
  border-radius: 5px;
}
.orderBlock .content .section .section-content td figure {
  width: 500px;
  border: 1px solid var(--grey-10);
  border-radius: 5px;
  height: 200px;
  margin: 0;
  position: relative;
}
.orderBlock .content .section .section-content td figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orderBlock .content .section .section-content a {
  color: blue;
  text-decoration: underline;
}
.orderBlock .content .section .section-content button {
  background: var(--grey-10);
  width: 100%;
  cursor: pointer;
  box-shadow: none !important;
  border-radius: 5px !important;
  color: var(--grey-90) !important;
}

.orderBlock .content .section .section-content h4 {
  color: var(--main);
}

.orderBlock .content .section .section-content canvas { 
  width: 500px;
  height: 200px;
}

.orderBlock .content .buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 20px;
}


.pack-container {
  display: flex;
  justify-content: space-between;
}
.pack-item {
  flex: 1;
  position: relative;
  max-width: 33%;
}
.pack-item.image {
  max-width: 25%;
}
.pack-item.image figure {
  margin: 0;
  border: 1px solid var(--grey-20);
  border-radius: 5px;
  margin-top: 50px;

}
.pack-item.image figure img {
  padding: 10px;
}
.pack-container .label {
  position: absolute;
  top: 38px;
  left: 15px;
  background: white;
  padding: 0 5px;
}

.pack-selectbox {
  width: 90%;
  border: 1px solid var(--grey-20);
  padding: 15px;
  border-radius: 5px;
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  margin-top: 50px;
}
.pack-item .pack {
  font-size: 14px;
  transition: .2s;
  cursor: pointer;
}
.pack-item .pack:hover {
  background-color: var(--grey-5);
}
.pack-item .pack.active {
  background-color: var(--grey-10);
}
.pack-item .pack.active:hover {
  background-color: var(--grey-20);
}
.pack-item figure img {
  object-fit: cover;
    background-size: cover;
    width: 100%;
}

.pack-item .wallet-container {
  width: 90%;
}

.pack-item .wallet {
  margin: 20px 0;
}
.pack-item .wallet h6 {
  margin: 0;
  transition: .2s;
  color: var(--grey-90);
  display: inline;
  cursor: pointer;
}
.pack-item .wallet h6:hover {
  border-bottom: 1px solid var(--grey-90);
}
.pack-item .wallet-container .buttonWrapper.S {
  margin: 15px 0;
}


.orderBlock .content.list {
  min-height: 95vh;

}


.orderBlock .content.list .section .section-content table {
  border: none;
  border-spacing: 0;
  border-radius: 5px;
  overflow: hidden;
}
.orderBlock .content.list .section .section-content tbody td:last-child {
  border-right: 1px dotted var(--grey-30);
}
.orderBlock .content.list .section .section-content tbody td:first-child {
  border-left: 1px dotted var(--grey-30);
}
.orderBlock .content.list .section .section-content tbody td {
  border-bottom: 1px dotted var(--grey-30);
  max-width: 20%;
}
.orderBlock .content.list .section .section-content th,
.orderBlock .content.list .section .section-content td {
  text-align: left;
  padding: 10px;
  white-space: nowrap;
}
.orderBlock .content.list .section .section-content th {
  background-color: #787679;
  color: white;
}

.orderBlock .content.list .id {
  cursor: pointer;
  font-weight: 900;
  color: var(--main);
}
.orderBlock .content.list .id:hover {
  text-decoration: underline;
}
.orderBlock .content.list .buttons {
  gap: 5px;
  margin-bottom: 0;
  justify-content: unset;
}
.orderBlock .content.list .button {
  color: var(--sub) !important;
  border: 1px solid var(--sub);
  padding: 5px 10px;
  height: auto;
  text-decoration: none !important;
  transition: .2s;
}
.orderBlock .content.list .button:hover {
  background-color: var(--sub);
  color: white !important;
}

.orderBlock .content .selectField .selectFieldOptions {
  /* text-transform: uppercase; */
  max-height: 300px;
  overflow-y: scroll;
}

.orderBlock .newbutton {
  margin: 10px auto 10px 10px ;
  border: 1px solid var(--main);
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--main);
  transition: .2s;
  cursor: pointer;
}
.orderBlock .newbutton:hover {
  background-color: var(--main);
  color: white;
}

.orderBlock .inputFieldWrapper input {
  border: 1px solid var(--grey-20);
}
.orderBlock .inputFieldWrapper.extra_info { 
  margin-top: 10px;
}

@media only screen and (max-width: 800px) {
  .orderBlock .tab .tabtitle {
    display: none;
  }
  .orderBlock .content .section .section-content .inputs {
    width: 100%;
    flex-direction: column;
  }
  .orderBlock .content .section .section-content .inputs .inputFieldWrapper {
    max-width: 100% !important;
  }
  .orderBlock .content .section h2.second {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
  }
  .orderBlock .content .section h2 .title-item {
    max-width: 100%;
    width: 100%;
  }
  .pack-container {
    flex-direction: column;
  }
  .pack-item {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .pack-item.image {
    max-width: 100%;
    width: 100%;
    order: -1;
  }
  .orderBlock .content .section .section-content td figure {
    width: 100%;
  }


  .orderBlock .content.bon .section .section-content table, .orderBlock .content.bon .section .section-content th, .orderBlock .content.bon .section .section-content td {
        display: block;
    }

    .orderBlock .content.bon .section .section-content th, .orderBlock .content.bon .section .section-content td {
        width: 100%;
    }
  }


/* ContactBlock */

.contactBlock {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactBlock .content {
  background-color: white;
  height: 80vh;
  width: 70vw;
  border: 1px solid var(--grey-30);
  border-radius: 5px;
  z-index: 1;
  position: fixed;
  display: flex;
  overflow: hidden;
}
.contactBlock .content .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
  transition: .25s;
}
.contactBlock .content .close-icon:hover {
  color: var(--main);
}
.contactBlock .background {
  background-color: black;
  opacity: .2;
  position: fixed;
  width: 100%;
  height: 100%;
}
.contactBlock .content .side {
  width: 70%;
  height: 100%;
}

.contactBlock .content .side.left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.contactBlock .content .side.left .contact-card {
  width: 70%;
  background: var(--grey-5);
  height: 25%;
  border-radius: 5px;
  display: flex;
  border: 1px solid var(--grey-10);
  min-height: 180px;
  /* box-shadow: 0 0 10px rgba(0,0,0,.1); */

}
.contactBlock .content .side.left .contact-card figure {
  /* background-color: var(--main_soft); */
  background-color: white;
  border: 1px solid var(--grey-10);
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  width: 30%;
  max-width: 120px;
  position: relative;
  left: -10%;
  height: 70%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 5px;
}
.contactBlock .content .side.left .contact-card figure img {
  width: auto;
  height: 100%;
  padding: 15px;
}

.contactBlock .content .side.left .contact-card .contact-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 70%;
}
.contactBlock .contact-card-content h2 {
  margin: 0;
  color: var(--grey-90);
  font-family: ShadowIntroLigth;
  font-size: 20px;
  font-weight: 900;
}
.contactBlock .contact-card-content h3 {
  font-size: 13px;
  color: var(--grey-70);
  margin: 0;
}

.contactBlock .contact-card-content .contacts {
  display: flex;
  flex-direction: column;
}
.contactBlock .contact-card-content .contacts .contact-block {
  display: flex;
  color: var(--grey-90);
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
}

.contactBlock .contact-card-content .socials {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.contactBlock .contact-card-content .socials a {
  padding: 5px;
  background-color: var(--main);
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .25s;
  width: 25px;
  height: 25px;
}

.contactBlock .contact-card-content .socials a:hover {
  background-color: var(--main_soft);
}

.contactBlock .content .side.right {
  background-color: var(--grey-5);
  width: 30%;
  padding: 20px;
}
.contactBlock .content .side.right figure {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--main);
  margin: 30px 0;
}
.contactBlock .content .side.right figure img {
  height: 100%;
  width: auto;
}
.contactBlock .content .side.right .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 110px);
  gap: 40px;
}
.contactBlock .content .side.right h2 {
  /* font-family: Heebo,Helvetica,sans-serif; */
  font-family: ShadowIntroLigth;
  color: var(--grey-80);
  width: 100%;
  min-width: 100%;
  display: flex;
  font-size: 26px;
  font-weight: 9  00;
  margin: 0;

}
.contactBlock .content .side.right .contact-us {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.contactBlock .content .side.right .contact-us .contact-block {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--grey-70);
  margin: 5px 0 ;
}
.contactBlock .content .side.right .contact-us .contact-block .icon {
  width: 50px;
  height: 30px;
}
.contactBlock .content .side.right .address {
  display: flex;
  align-items: center;
  color: var(--grey-70);
  flex-wrap: wrap;
}
.contactBlock .content .side.right .address img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.contactBlock .content .side.right .social-media {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin-top: auto;
}

.contactBlock .content .side.right .social-media .icon {
  background-color: var(--main);
  color: white;
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
  transition: .25s;
  margin: 5px;
  border-radius: 5px;
}
.contactBlock .content .side.right .social-media .icon:hover {
  background-color: var(--main_soft);
}

@media only screen and (max-width: 1340px) {
  .contactBlock .content {
    flex-direction: column;
  }
  .contactBlock .content .side.right {
    order: -1;
    width: 100%;
    height: 20%;
    display: flex;
    border-bottom: 1px solid var(--grey-20);
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}
  .contactBlock .content .side.right figure {
    height: 100%;
    min-width: 150px;
    width: 150px;
    border-bottom: 0;
    margin: 0 30px;
    display: none;
  }
  .contactBlock .content .side.right figure img {
    width: 100%;
    height: auto;
  }
  .contactBlock .content .side.right .container {
    flex-direction: row;
    height: 100%;
    width: 100%;
  }
  .contactBlock .content .side.right .contact-us {
    width: 30%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .contactBlock .content .side.right .contact-us .contact-block {
    margin: 0;
    font-size: 15px;
    width: 100%;
    max-width: 150px;
    white-space: nowrap;
  }
  .contactBlock .content .side.right .contact-us .contact-block .icon {
    min-width: 30px;
    min-height: 30px;
  }
  .contactBlock .content .side.right .address img {
    width: 30px;
    height: 30px;
  }
  .contactBlock .content .side.right .address {
    align-items: flex-start;
    justify-content: center;
  }
  .contactBlock .content .side.right .address img {
    display: none;
  }
  .contactBlock .content .side.right .address span {
    white-space: nowrap;
  }

  .contactBlock .content .side.right h2 {
    white-space: nowrap;
    font-size: 20px;
    justify-content: center;
  }
  .contactBlock .content .side.right .social-media { 
    justify-content: center;
    margin-top: 0;
    align-items: flex-start;
    width: 33%;
  }
  .contactBlock .content .side.right .social-media .icon {
    width: 25px;
    height: 25px;
  }

  .contactBlock .content .side.left {
    width: 100%;
    overflow: scroll;
    height: 70%;
    justify-content: unset;
    margin: 30px 0 0;
  }
}
@media only screen and (max-width: 800px) {
  .contactBlock .content {
    overflow: auto;
  }
  .contactBlock .content .side.right {
    flex-direction: column;
    height: unset;
  }
  .contactBlock .content .side.right figure {
    display: flex;
    width: 100%;
    max-width: 150px;
    margin: 50px auto;
  }
  .contactBlock .content .side.right .container {
    flex-direction: column;
    align-items: center;
  }

  .contactBlock .content .side.right .address {
    width: 100%;
  }
  .contactBlock .content .side.right .address span { text-align: center;}
  .contactBlock .content .side.right .contact-us {
    width: 100%;
  }
  .contactBlock .content .side.right h2 { margin-bottom: 10px;}
  .contactBlock .content .side.left { 
    overflow: unset;
    height: 100%;
  }
  .contactBlock .content .side.right .social-media {
    width: 100%;
  }
  .contactBlock .contact-card-content .socials a {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .contactBlock .content {
    width: 90%;
    height: 90vh;
  }
  .contactBlock .contact-card-content h3 {
    font-size: 10px;
  }
}
/* .main_environment .cardWrapper { 
  display: flex;
  flex-wrap: wrap;
}
.main_environment .card {
  width: 400px;
  height: 120px;
  margin: 5px;
  display: flex;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.05);
  background-color: #fff;
  border-radius: 5px;
  color: var(--grey-50);
  font-size: 13px;
  overflow: hidden;
  position: relative;
  text-align: left;
  transition: transform .35s,box-shadow .35s;
  border: 1px solid var(--grey-10);
  flex-flow: unset;
}
.main_environment .card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0px;
  width: 100%;
  background: var(--main);
  transition: 0.1s;
}

.main_environment .card:hover, .main_environment .card.active {
  transform: scale(1.03);
}
.main_environment .card:hover::after, .main_environment .card.active::after {
  height: 2px;
}
.main_environment .card figure {
  width: 100px;
  min-width: 100px;
  margin: 0;
  position: relative;
  height: 100%;
}
.main_environment .card figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  padding: 5px;
}
.main_environment .card .cardContent {
  display: flex;
  flex: 1 1;
  flex-flow: column nowrap;
  overflow: hidden;
  position: relative;
  justify-content: center;
}
.main_environment .card .res_name {
  display: flex;
  flex-direction: column;
  max-height: 65px;
  margin-bottom: 0;
  max-width: 230px;
  padding: 15px;
  border: none;
}
.main_environment .card h2, .main_environment .card h3 {
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
  font-weight: unset !important;
  font-size: 13px;
  display: block !important;
}
.main_environment .card .cardContent .cardRow {
  align-items: center;
  display: flex;
  padding: 0 15px 15px;
}
.main_environment .card .icon {
  height: 30px;
  margin-right: 10px;
  max-width: 30px;
  padding: 5px;
  width: 30px;
  flex: 1 1;
}
.main_environment .card .cardContent .cardRow span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.main_environment .card .cardContent::before {
  background-color: var(--grey-20);
  content: "";
  height: 60%;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 1px;
}
.main_environment .card .cardLinks {
  border: none;
  flex-direction: column;
  height: auto;
  min-width: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_environment .card .cardLinks a {
  align-items: center;
  display: flex;
  padding: 10px;
  text-align: right;
  color: var(--sub);
  text-decoration: none;
  transition: .25s;
  white-space: nowrap;
  height: 100%;
  justify-content: center;
  cursor: pointer;
}
.main_environment .card .cardLinks a:hover {
  background-color: var(--grey-5);
}

.main_environment .card .cardLinks .icon {
  margin-right: unset !important;
  padding: unset !important;
} */


.main_environment .head-image {
  height: 75vh;
  width: 100%;
  position: relative;
  margin-top: 46px;
  /* background-image: url("./library/images/Mockup-OSN.png");
  background-size: cover; */
  /* border-bottom: 10px solid linear-gradient(135deg,var(--main) 25%,var(--sub) 75%); */
  overflow: hidden;
  margin-bottom: 30px;
  z-index: -1;
}
.main_environment .head-image::after {
  position: absolute;
  content: "";
  background: linear-gradient(135deg,var(--main) 25%,var(--sub) 75%);
  width: 100%;
  height: 10px;
  bottom: 0;
  z-index: 1;
  display: block;
}
.main_environment .head-image img{
  height: 130%;
  width: 100%;
  /* position: absolute; */
  object-fit: cover;
}

.main_environment h2 {
  font-family: Heebo,Helvetica,sans-serif;
  color: var(--main);
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 36px;
  margin: 20px 0;
}

.main_environment h3 {
  font-family: ShadowIntroLigth;
  color: var(--main);
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 36px;
  font-weight: 100;
  margin: 20px 0;
}
.main_environment p {
  font-family: Heebo,Helvetica,sans-serif;
  color: var(--grey-90);
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
  line-height: 21px;
}
.main_environment .search-engine {
  width: 70%;
  display: flex;
  margin: 50px auto;
  flex-direction: column;
  align-items: center;
}
.main_environment .search-engine .input-wrapper {
  width: 70%;
}
.main_environment .search-engine .input-wrapper h4 {
  font-size: 14px;
  color: var(--grey-30);
  font-family: Heebo,Helvetica,sans-serif;
  margin: 0;
  margin-bottom: 10px;
}
.main_environment .search-engine .input {
  height: 50px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  border-radius: 5px;
  border: none;
  overflow: hidden;
}

.main_environment .search-engine .input input {
  background-color: hsla(0,0%,100%,.5);
  border-radius: 0;
  color: var(--grey-90);
  font-family: "openSans",sans-serif;
  font-size: 13px;
  height: 100%;
  padding: 15px 10px;
  position: relative;
  width: 100%;
  z-index: 100;
  border: none;
}
.main_environment .search-engine .input span {
  height: 50px;
  overflow: hidden;
  position: relative;
  transition: .25s;
  width: 50px;
  background: linear-gradient(135deg,var(--main) 25%,var(--sub) 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  color: white;
  cursor: pointer;
}
.main_environment .search-engine .input span .icon {
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: .5s;
}

.main_environment .search-engine .input span .icon:first-child {
  height: 25px;
  opacity: 0;
  -webkit-transform: rotate(-45deg) translate(-10%,-70%);
  transform: rotate(-45deg) translate(-10%,-70%);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 25px;
}
.main_environment .search-engine .input span:hover .icon:first-child {
  height: 25px;
  opacity: 1;
  -webkit-transform: rotate(0deg) translate(-50%,-50%);
  transform: rotate(0deg) translate(-50%,-50%);
  width: 25px;
}
.main_environment .search-engine .input span .icon:nth-of-type(2n) {
  height: 25px;
  opacity: 1;
  width: 25px;
}
.main_environment .search-engine .input span:hover .icon:nth-of-type(2n) {
  height: 25px;
  opacity: 0;
  -webkit-transform: rotate(45deg) translate(-15px,-5px);
  transform: rotate(45deg) translate(-15px,-5px);
  width: 25px;
}

.main_environment .cards {
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px auto;

}

.main_environment .cards .card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.05);
  color: var(--grey-50);
  display: flex;
  flex-flow: column nowrap;
  font-size: 13px;
  height: 304px;
  /* margin: 0 25px 50px; */
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  text-align: left;
  transition: box-shadow .35s,-webkit-transform .35s;
  transition: transform .35s,box-shadow .35s;
  transition: transform .35s,box-shadow .35s,-webkit-transform .35s;
  width: 212px;
  border: 1px solid var(--grey-10);
  transform-origin: center;
}
.main_environment .cards .card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
}
.main_environment .cards .card figure {
  backface-visibility: hidden;
  background-color: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  background-color: #fff;
  box-sizing: unset;
  position: relative;
  z-index: 5;
}
.main_environment .cards .card figure img {
  max-height: 100%;
  max-width: 100%;
  transition: .2s;
}
.main_environment .cards .card .names {
  border-bottom: 1px solid var(--grey-10);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 55px;
  margin-bottom: 6px;
  max-height: 55px;
  padding: 0 15px 10px;
}
.main_environment .cards .card .names h2 {
  margin: 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  font-size: 13px;
  font-family: "heebo Light",sans-serif;
  font-weight: unset;
  justify-content: flex-start;
}
.main_environment .cards .card .names h3 {
  margin: 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "heebo Light",sans-serif;
  font-weight: unset;
  border-left: 2px solid rgba(0,0,0,.2);
  color: rgba(0,0,0,.3);
  font-family: "OpenSans",sans-serif;
  font-weight: 700;
  font-size: 11px;
  padding-left: 5px;
  justify-content: flex-start;
}

.main_environment .cards .card .cardRow {
  align-items: center;
  display: flex;
  padding: 0 15px 5px;
}

.main_environment .cards .card .cardRow span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.main_environment .cards .card .icon {
  height: 30px;
  margin-right: 10px;
  max-width: 30px;
  padding: 5px;
  width: 30px;
}

.main_environment .cards .card .next {
  width: 100%;
  /* border-bottom: 4px solid var(--sub); */
  margin-top: auto;
  border-top: 1px solid var(--grey-10);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: .25s;
  color: var(--sub);
  cursor: pointer;
}
.main_environment .cards .card .next:hover {
  background-color: var(--grey-5);
}

.main_environment .cards .card:after {
  background: linear-gradient(135deg,var(--main) 25%,var(--sub) 75%);
  bottom: -1px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: .25s;
}

.main_environment .cards .card:hover:after {
  height: 3px;
}

@media only screen and (max-width: 860px) {
  .main_environment .search-engine {
    width: 100%;
  }
  .main_environment .cards {
    justify-content: center;
    gap: 20px;
  }
  .main_environment .head-image {
    height: 45vh;
  }
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-decoration: none !important;
  color: inherit !important;
}